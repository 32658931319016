<template>
  <div class="component-wrap top-wrap">
    <div>
      <h3 class="name">{{ stateData.detail.detailName }}</h3>
      <div class="source">
        {{ $t("FaceToFaceSource") }}:
        <template v-if="stateData.detail.taskType">
          【{{ taskCardType[stateData.detail.taskType].name }}】
          {{ stateData.detail.projectName }}-
          {{ stateData.detail.taskName }}
        </template>
        <template v-else>
          【{{ $t("CM_CT") }}】{{ stateData.detail.detailName }}
        </template>
      </div>
      <div class="source">
        {{ $t("EvaluateStartTime") }}：{{ dateFormat(stateData.detail.startTime) }}
      </div>
    </div>
    <a-button type="primary" @click="back">
      {{ $t("go_back") }}
      <!-- 返回 -->
    </a-button>
  </div>
  <div class="component-wrap">
    <div class="component-tit">
      <!-- 学员列表 -->
      {{ $t("homework.StudentList") }}
    </div>
    <div class="filter-wrap">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="stateData.reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.userName"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch"
            />
            <!-- 请输入名称 -->
            <a-button
              style="margin-left: 10px"
              v-if="stateData.detail.completeCondition == 4"
              type="primary"
              @click="$refs.importGradesRef.show(stateData.detail)"
              >{{ $t("ImportGrades") }}</a-button
            >
          </div>
        </template>
      </ListFilter>
    </div>
    <div class="table-wrap">
      <a-table
        size="middle"
        :columns="stateData.columns"
        :data-source="stateData.dataList"
        rowKey="userId"
        :bordered="false"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <template #realName="{ text }">
          <OpenData type="userName" :openid="text" />
        </template>
        <template #department="{ text }">
          <OpenData type="departmentName" :openid="text" />
        </template>
        <template #status="{ text }">
          <span v-if="text === 1" style="color: #44b44f">{{
            $t("Evaluated")
          }}</span>
          <span v-else style="color: #e8673e">{{
            $t("homework.NotEvaluated")
          }}</span>
        </template>
        <template #time="{ text }">
          {{ dateFormat(text) }}
        </template>
        <template #score="{ text, record }">
          {{ record.status === 1 ? text.toFixed(2) : "--" }}
        </template>
        <template #action="{ record }">
          <a-button type="link" @click="jump(record)">
            {{ record.status === 1 ? $t("cm_view") : $t("course.evaluate") }}
            <!--批阅 查看-->
          </a-button>
        </template>
      </a-table>
    </div>
  </div>
  <!-- 评价 -->
  <a-modal
    v-model:visible="stateData.questionnaireVisible"
    :title="$t('train.evaluation')"
    width="1050px"
    :footer="null"
    :maskClosable="false"
    :keyboard="false"
    destroyOnClose
    :bodyStyle="{ overflow: 'auto', height: '70vh', padding: '0' }"
  >
    <questionnaire
      ref="questionnaireRef"
      :taskId="stateData.taskId"
      :did="stateData.detailId"
      :rid="stateData.questionnaireId"
      :userId="stateData.userId"
      :evalType="5"
      :progress="stateData.progress"
      @on-close="handleSearch"
    />
  </a-modal>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { getCurrentInstance, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getTrainEvaluationMember } from "@/api/train";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
import { dateFormat } from "@/utils/tools";
import { taskCardType } from "@/utils/business";
import questionnaire from "@/views/questionnaire/detail2";

const { t: $t } = useI18n();
const router = useRouter();
const route = useRoute();
const { proxy } = getCurrentInstance();
const stateData = reactive({
  taskId: Number(route.query.id || 0),
  detailId: Number(route.query.did || 0),
  detail: {},
  reviewStatus: [
    { id: 1, name: $t("Evaluated") }, // 已评估
    { id: 2, name: $t("homework.NotEvaluated") }, // 未评估
  ],
  columns: [
    {
      title: $t("Pub_Lab_UserName"),
      // title: "姓名",
      dataIndex: "realName",
      ellipsis: true,
      width: 180,
      slots: { customRender: "realName" },
    },
    {
      title: $t("login.account"),
      // title: "账号",
      dataIndex: "account",
      ellipsis: true,
      width: 150,
    },
    {
      title: $t("homework.Department"),
      // title: "所属部门",
      dataIndex: "departmentName",
      ellipsis: true,
      width: 180,
      slots: { customRender: "department" },
    },
    {
      title: $t("idp.evaluate_status"),
      // title: "评估状态",
      dataIndex: "status",
      width: 140,
      slots: { customRender: "status" },
    },
    {
      title: $t("LB_Favorite_Score"),
      // title: "评分",
      dataIndex: "score",
      width: 160,
      slots: { customRender: "score" },
    },
    {
      title: $t("AssessmentTime"),
      // title: "评估时间",
      dataIndex: "submitTime",
      width: 160,
      slots: { customRender: "time" },
    },
    {
      title: $t("homework.table.operate"),
      // title: "操作",
      dataIndex: "action",
      fixed: "right",
      align: "center",
      width: 90,
      slots: { customRender: "action" },
    },
  ],
  loading: false,
  dataList: [],
  questionnaireVisible: false,
  progress: 0,
  questionnaireId: 0,
  userId: 0,
});

let queryParam = reactive({
  userName: "",
  status: 0,
  taskId: stateData.taskId,
  detailId: stateData.detailId,
});
const pagination = reactive({
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: ["10", "30", "50", "100"],
  current: 1,
  pageSize: 10,
  total: 0,
  onChange: (page, pageSize) => {
    pagination.current = page;
    getList();
  },
  onShowSizeChange: (current, size) => {
    pagination.current = size;
    getList();
  },
});
const getList = async () => {
  let form = JSON.parse(JSON.stringify(queryParam));
  form.page = pagination.current;
  form.pageSize = pagination.pageSize;
  stateData.loading = true;
  const res = await getTrainEvaluationMember(form);
  if (res.ret === 0) {
    stateData.detail = res.data.taskEvalnation[0];
    stateData.dataList = res.data.list || [];
    pagination.total = res.data.totals;
  }
  stateData.loading = false;
};
getList();

const handleTableChange = ({ current, pageSize }, filters, sorter) => {
  pagination.current = current;
  pagination.pageSize = pageSize;
  getList();
};

const handleSearch = () => {
  pagination.current = 1;
  stateData.questionnaireVisible = false;
  getList();
};
const switchStatus = (arr) => {
  queryParam.status = arr[0];
  handleSearch();
};

const back = () => {
  router.go(-1);
};

const questionnaireRef = ref(null);
function jump(item) {
  let tip = ''
  if (Date.parse(new Date()) / 1000 < stateData.detail.startTime) {
    tip = $t("NotYetDueForAssessment");
  }
  // if (
  //   stateData.detail.endTime &&
  //   Date.parse(new Date()) / 1000 > stateData.detail.endTime
  // ) {
  //   tip = $t("EvaluationTimeIsOver");
  // }
  if (item.status != 1 && tip) return proxy.$message.error(tip);
  stateData.questionnaireId = item.evaluationId;
  stateData.userId = item.userId;
  stateData.progress = item.status === 1 ? 100 : 0;
  stateData.questionnaireVisible = true;
}
</script>

<style lang="less" scoped>
.component-wrap {
  // min-height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);

  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 0 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;
    border-bottom: 1px solid #f4f4f4;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.top-wrap {
  .mixinFlex(space-between, center);
  margin-bottom: 20px;
  color: #333;
  .name {
    font-size: 20px;
    font-weight: 600;
  }
  .source {
    font-size: 14px;
  }
}
</style>
